import { useCall, useEthers } from "@usedapp/core";
import { BigNumber, constants } from "ethers";
import { tokenSale } from "./constants";

/**
 * @returns Whether the user has bought any tokens
 */
const useHasBoughtTokens = () => {
  const { account } = useEthers();
  const { value } = useCall({
    contract: tokenSale,
    args: [account || constants.AddressZero],
    method: "tokensBought",
  }) ?? { value: undefined };

  if (!value) return false;
  if (!BigNumber.isBigNumber(value[0])) return false;

  const amount = value[0] as BigNumber;
  return amount.gt(0);
};
export default useHasBoughtTokens;