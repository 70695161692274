import { createContext, useContext, useState } from "react";
import { AiOutlineHourglass } from "react-icons/ai";

const LoadingContext = createContext<{ setLoading: (loading: boolean) => void; }>({
  setLoading: () => { },
});

export default function LoadingOverlay({ children }: { children?: React.ReactNode; }) {
  const [isLoading, setLoading] = useState(true);

  return (
    <LoadingContext.Provider value={{ setLoading }}>
      {children}

      {isLoading && (
        <div className="fixed bg-opacity-70 bg-black inset-0 z-[99999] flex flex-row items-center justify-center">
          <div className="flex flex-col gap-2 items-center justify-center">
            <AiOutlineHourglass size={60} color="white" className="animate-bounce" />
            <p className="text-white font-bold text-3xl">We are connecting to the blockchain...</p>
          </div>
        </div>
      )}
    </LoadingContext.Provider>
  );
}

export function useLoadingOverlay() {
  return useContext(LoadingContext);
}