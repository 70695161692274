import { useCall } from "@usedapp/core";
import { BigNumber, constants } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { nftSale } from "./constants";

// We add this to the price to account for changes in the price of MATIC
const EXTRA_WEI = parseEther("0.01");

/**
 * @returns The price of the NFT in USD
 */
const useNFTPrice = () => {
  const { value } = useCall({
    contract: nftSale,
    args: [],
    method: "price",
  }) ?? { value: constants.Zero };

  const price = value[0];

  if (BigNumber.isBigNumber(price)) return price;
  else return constants.Zero;
};

const useNFTPriceWei = () => {
  const { value } = useCall({
    contract: nftSale,
    args: [],
    method: "getTokenPriceWei",
  }) ?? { value: constants.Zero };

  const price = value[0];

  if (BigNumber.isBigNumber(price)) return price.add(EXTRA_WEI);
  else return constants.Zero;
};

export { useNFTPriceWei };
export default useNFTPrice;
