import Modal from "react-modal";

export default function InfoModal({ children, isOpen, setOpen }: {
  children?: React.ReactNode;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          border: "none",
          borderRadius: "0.5rem",
          padding: "0",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
        },
      }}
      contentLabel="KYC Modal"
    >
      <div className="flex flex-col items-center px-12 py-6 md:py-12 w-[95vw] md:w-[50vw] text-white font-bold bg-[#170017] gap-4 rounded-xl max-h-[90vh] overflow-y-scroll">
        {children}
        {/* <p className="font-bold text-2xl my-6">
          To access your {unclaimedTokens && prettyFormatEther(unclaimedTokens)} XVR tokens you will need to complete a KYC procedure.
        </p>
        <div className="flex flex-row items-center gap-4">
          <div className="w-[30px] h-[30px]">
            <BsPersonBoundingBox size={30} />
          </div>
          <p>
            If you have not completed the KYC procedure, please click the button below.
          </p>
        </div>

        <div className="flex flex-row items-center gap-4">
          <div className="w-[30px] h-[30px]">
            <BsHourglassSplit size={30} />
          </div>
          <div className="flex flex-col gap-2">
            <p>
              If you have already completed the KYC procedure, please wait for the verification to complete.
              You will receive an email when the verification is complete.
            </p>
            <p className="font-bold text-red-200">
              Make sure to also check your SPAM folder.
            </p>
          </div>
        </div> */}
      </div>
    </Modal>

  );
}