import { useCall } from "@usedapp/core";
import { BigNumber } from "ethers";
import { tokenSale } from "./constants";

const useTokenPrice = () => {
    const { value } = useCall({
        contract: tokenSale,
        args: [],
        method: "tokenPrice"
    }) ?? { value: undefined };

    if (!value) return undefined;

    const amount = value[0] as BigNumber;
    return amount.toNumber();
};

export default useTokenPrice;