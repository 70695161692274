export async function logKYCAttempt(email: string) {
  const response = await fetch(
    "https://us-central1-xvr-polygon.cloudfunctions.net/widgets/log_kyc_attempt",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

export async function logPurchase(
  email: string,
  amount: string,
  address: string,
) {
  const response = await fetch(
    "https://us-central1-xvr-polygon.cloudfunctions.net/widgets/log_purchase",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, amount, address }),
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

type KYCResponse = {
  ok: false;
} | {
  ok: true;
} | {
  ok: true;
  r: string;
  s: string;
  v: number;
  emailHash: string;
};

export const checkIfKYCCompleted: (
  email: string,
  address: string,
) => Promise<KYCResponse> = async (email, address) => {
  if (!email) return { ok: false };
  const request = await fetch(
    `https://us-central1-xvr-polygon.cloudfunctions.net/widgets/can_buy_token?email=${
      encodeURIComponent(email)
    }&address=${encodeURIComponent(address)}`,
  );
  if (!request.ok) throw new Error("fail");
  const response = await request.json() as KYCResponse;
  return response;
};

interface Code {
  influencerAddress: string;
  salePercent10000: number;
  extraTokens10000: number;
  r: string;
  s: string;
  v: number;
  message: string;
}

export interface NFTCode {
  influencerAddress: string;
  salePercent10000: number;
  r: string;
  s: string;
  v: number;
  message: string;
}

export const getAffiliateCode: (
  id: string,
) => Promise<Code> = async (id) => {
  const request = await fetch(
    `https://us-central1-xvr-polygon.cloudfunctions.net/widgets/aff_link?id=${id}`,
  );
  if (!request.ok) throw new Error("fail");
  const response = await request.json() as Code;
  return response;
};

export const getNftAffiliateCode: (
  id: string,
) => Promise<NFTCode> = async (id) => {
  const request = await fetch(
    `https://us-central1-xvr-polygon.cloudfunctions.net/widgets/nft_aff_link?id=${id}`,
  );
  if (!request.ok) throw new Error("fail");
  const response = await request.json() as NFTCode;
  return response;
};

export async function getNftCodeData(id: string) {
  try {
    const info = await getNftAffiliateCode(id);
  localStorage.setItem("nftAffiliateCode", id);
    return info;
  } catch (e) {
    localStorage.removeItem("nftAffiliateCode");
    return null;
  }
}