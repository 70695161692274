import { useCall } from "@usedapp/core";
import { nftSale, tokenSale } from "./constants";

/// Is the XVRCoin sale paused?
const usePaused: () => boolean = () => {
    const { value } = useCall({
        contract: tokenSale,
        args: [],
        method: "paused"
    }) ?? { value: [false] };

    if (!value) return false;

    return !!value[0];
};

/// Is the XVR NFT sale paused?
export const useNFTPaused: () => boolean = () => {
    const { value } = useCall({
        contract: nftSale,
        args: [],
        method: "paused"
    }) ?? { value: [false] };

    if (!value) return false;

    return !!value[0];
};

export default usePaused;