import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { router } from "./App";

export default function KYCCompletedPage() {
  const { data } = useParams();

  const dataStr = useMemo(() => {
    if (!data) return "{}";
    const urldecoded = decodeURIComponent(data);
    const buf = Buffer.from(urldecoded, "base64");
    return buf.toString("utf8");
  }, [data]);

  const { email } = JSON.parse(dataStr) || { email: undefined };

  useEffect(() => {
    if (!email || typeof email !== "string") return;
    console.log("KYC Completed for:", email);
    localStorage.setItem("email", email);
    router.navigate("/t", { replace: true, state: { email } });
  }, [email]);

  if (!data) return <div className="w-full h-full"></div>;
  if (!email || typeof email !== "string") return <div className="w-full h-full"></div>;

  return <div className="w-[100vw] h-[100vh] text-white pt-6 flex flex-col gap-2">
    <h1 className="text-3xl font-bold">KYC completed</h1>
    <p className="text-xl font-bold">Redirecting...</p>
  </div>;
}