function Navbar() {
    return (
        <div className="font-neuropol bg-transparent flex flex-row justify-center mb-2">
            <ul className="flex text-pinkMain py-2 flex-col sm:flex-row mt-6
            text-base md:text-[0.6rem] lg:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] 
            space-y-1 sm:space-y-0 sm:space-x-20">
                <Link
                    href="http://hotnftstars.io/"
                    text="HOT NFT STARS"
                />
                <Link
                    href="https://www.xvrprojectsystem.com/ico#whitepaper"
                    text="WHITEPAPER XVRCoin"
                />
                <Link
                    href="http://hotnftstars.io/roadmap/"
                    text="LITEPAPER NFT"
                />
                <Link
                    href="https://zgloszenie.bogowieseksu.tv/"
                    text="COOPERATION"
                />
            </ul>
        </div >
    );
}

export default Navbar;

function Link({ href, text }: { href: string, text: string; }) {
    return (
        <li>
            <a
                href={href}
                rel="noreferrer noopener"
                target="_blank"
                className="block font-light hover:scale-125 duration-200 hover:text-pinkLight whitespace-nowrap"
            >
                {text}
            </a>
        </li>
    );
}
