import { DAppProvider, Polygon, useEthers } from '@usedapp/core';
import { useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useParams } from 'react-router-dom';
import './App.css';
import BuySection from "./components/BuySection";
import Footer from "./components/Footer";
import LandingSection from "./components/LandingSection";
import LoadingOverlay, { useLoadingOverlay } from './components/LoadingOverlay';
import Navbar from "./components/Navbar";
import KYCCompletedPage from './kycCompletedPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigation isNFTon={true} />} />
      <Route path="/:code" element={<Navigation isNFTon={true} />} />
      <Route path="/n/:code" element={<Navigation isNFTon={true} />} />
      <Route path="/t" element={<Navigation isNFTon={false} />} />
      <Route path="/t/:code" element={<Navigation isNFTon={false} />} />
      <Route path="/kyc_completed/:data" element={<KYCCompletedPage />} />
      <Route path="/kyc_completed" element={<KYCCompletedPage />} />
      <Route path="/bl/:code" element={<BiAffiliateNavigation />} />
    </>
  )
);

function BiAffiliateNavigation() {
  const { code } = useParams();

  useEffect(() => {
    if (!code) {
      router.navigate(`/`, { replace: true });
      return;
    };

    localStorage.setItem("aff.code", code);
    localStorage.setItem("nftaff.code", code);

    router.navigate(`/n/${code}`, { replace: true, state: { code } });
  }, [code]);

  return (
    <>
      <LandingSection isNFTon={true} />
    </>
  );
}

function Navigation({ isNFTon }: { isNFTon: boolean; }) {
  const { code } = useParams();
  const connected = useEthers().active;
  const { setLoading } = useLoadingOverlay();

  useEffect(() => {
    const oldTokenCode = localStorage.getItem("aff.code");
    const oldNFTCode = localStorage.getItem("nftaff.code");
    const oldCode = isNFTon ? oldNFTCode : oldTokenCode;

    if (code) {
      if (oldCode !== code) {
        localStorage.setItem(isNFTon ? "nftaff.code" : "aff.code", code);
        const newPath = isNFTon ? `/n/${code}` : `/t/${code}`;
        router.navigate(newPath, { replace: true, state: { code } });
      }

      if (!oldNFTCode && !oldTokenCode) {
        window.location.href = "https://eroexpoweb3.com";
      }
    } else {
      if (oldCode) {
        const newPath = isNFTon ? `/n/${oldCode}` : `/t/${oldCode}`;
        router.navigate(newPath, { replace: true, state: { code: oldCode } });
      }
    }
  }, [code, isNFTon]);

  useEffect(() => {
    setLoading(!connected);
  }, [connected, setLoading]);

  return (
    <>
      <BuySection isNFTon={isNFTon} />
      <LandingSection isNFTon={isNFTon} />
    </>
  );
}

function App() {
  return (
    <DAppProvider config={{
      readOnlyChainId: Polygon.chainId,
      networks: [Polygon],
      readOnlyUrls: {
        [Polygon.chainId]: "https://polygon-mainnet.g.alchemy.com/v2/GjRWUWTuYcN8uimHjkaHz2SvtE2K_RpV",
      }
    }}>
      <LoadingOverlay>
        <IconContext.Provider
          value={{ color: "white", size: '30px' }}
        >
          <div className="App bg-[url('../public/A4.png')] bg-cover bg-no-repeat flex flex-col items-center">
            <div className='gradient w-full'>
              <Navbar />

              <RouterProvider router={router} />

              <Footer />
            </div>
            
          </div>
        </IconContext.Provider>
      </LoadingOverlay>
    </DAppProvider>
  );
}

export default App;
