const BidSection = () => {
    return <div className="w-full flex flex-col justify-center text-white mt-6">
        <video
            className="w-[400px] max-w-full lg:max-w-[30vw] aspect-square rounded-xl border-2 inline-block mx-auto border-pinkMain shadow-md shadow-pinkMain"
            src="https://xvrm.parlour.construction/a/0000.mp4"
            loop
            autoPlay
            muted
            playsInline
            preload="auto"
        >
            <source src="https://xvrm.parlour.construction/a/0000.mp4" type="video/mp4" />
        </video>

        <div className="text-pinkMain my-4 lg:max-w-[45vw] text-center mx-auto px-3">
            <p className="text-center text-lg">
            NFT #0 it is the most unique NFT. Owning it you will receive:
            </p>
            <ol className="list-decimal text-left">
                <li>1% of all sold NFTs on the primary market</li>
                <li>free access to all XVR parties, both open and closed and much, much more...</li>
                {/* <li>
                    free access to all events, that XVR ProjectSystem is participating in throughout the world, including:
                    <ul className="list-disc ml-4">
                        <li>backstage access during events with actresses and XVR</li>
                    </ul>
                </li>
                <li>possibility to star in a film along with one of the actresses cooperating with XVR</li>
                <li>possibility to take part in the first edition of the *Gods of sex* reality show</li>
                <li>permanent free access to the *Gods of sex* social network and all profiles created there</li>
                <li>permanent free access to all editions of the *Gods of sex* reality show</li>
                <li>permanent free access to XVR TV</li>
                <li>priority of entry to quizzes organized on XVR TV</li>
                <li>priority of entry to the XVR TV studio as a viewer during transmissions</li>
                <li>permanent free access to the metaverse platform XVR Planets</li> */}
            </ol>
            {/* <p className="text-left mt-2">The 1% bonus from NFT purchases will be sent to you automatically,
                to claim the other benefits you have to notify us beforehand.</p> */}
            <a
                rel="noreferrer noopener"
                className="text-left underline w-full block"
                href="https://opensea.io/assets/matic/0x9c8a30f7dbc97951104fafcfe85c9c50a7ecb34f/0#Body%20assets-item-description"
                target="_blank"
            >Learn more...
            </a>
        </div>

        <p className="text-xl">Auction end: <span className="font-bold">31.10.2023</span></p>
        <div className="w-full flex flex-row items-center justify-center">
            <a
                rel="noreferrer noopener"
                className={`px-3 py-1 mt-2 text-xl font-bold rounded-lg flex flex-row gap-2 items-center justify-center bg-pinkMain`}
                href="https://opensea.io/assets/matic/0x9c8a30f7dbc97951104fafcfe85c9c50a7ecb34f/0"
                target="_blank"
            >
                Bid on OpenSea
            </a>
        </div>

    </div>;
};

export default BidSection;
