import { Polygon, useContractFunction, useEtherBalance, useEthers } from "@usedapp/core";
import { BigNumber, constants, Contract, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import BuyNowButton from "../images/BuyNowButton.png";
import SaleABI from "../util/SaleABI.json";
import { checkIfKYCCompleted, getAffiliateCode, logPurchase } from "../util/api";
import usePaused from "../util/usePaused";
import KYCSection from "./KYCSection";
import ClaimSection from "./ClaimSection";
import useUnclaimedTokens, { testUnclaimed } from "../util/useUnclaimedTokens";
import useTokenPrice from "../util/useTokenPrice";
import useEurUsd from "../util/useEurUsd";
import { Code } from "./AffiliateCodeEntry";
import AddToWallet from "./AddToWallet";
import { useParams } from "react-router-dom";
import HowToBuyButton from "./HowToBuyButton";

type PurchaseStage = "connect" | "network" | "buy";
type KYCStage = "started" | "completed";
const usedNetwork = Polygon;

const addMumbai = (lib: ethers.providers.JsonRpcProvider) => {
    return lib.send("wallet_addEthereumChain", [
        {
            chainId: "0x89",
            chainName: "Polygon Mainnet",
            nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com/"],
        }
    ]);
};

const BuyButton: React.FC<{ amountMatic: BigNumber; amountUSD: number; }> = ({ amountMatic, amountUSD }) => {
    const [email, setEmail] = useState<string>("");

    const [stage, setStage] = useState<PurchaseStage>("connect");
    const [kycStage, setKycStage] = useState<KYCStage>("started");

    const [kycSignature, setKycSignature] = useState<{ r: string, s: string, v: number, emailHash: string; } | null>(null);
    const [affiliateCode, setAffiliateCode] = useState<Code | null>(null);
    const { code: affiliateCodeRaw } = useParams();

    const [errorMessage, setErrorMessage] = useState("");
    const { active, activateBrowserWallet, account, switchNetwork, chainId, library } = useEthers();

    const buyMethod = selectBuyMethod(!!kycSignature, !!affiliateCode);
    const { state, send } = useContractFunction(new Contract("0x7dc713427BCB6a110fddAF29051EE078A92457EF", SaleABI), buyMethod, { transactionName: buyMethod });

    const paused = usePaused();
    const unclaimedTokens = useUnclaimedTokens() || constants.Zero;
    const price = useTokenPrice() || 0;
    const eurUsd = useEurUsd();


    const isKycRequired = useMemo(() => {
        if (price === 0 || eurUsd.eq(constants.Zero)) return true;
        // this will require KYC if the purchase was above 1000 EUR
        return unclaimedTokens.div(eurUsd).div(price).div("10000000000").gte(950);
    }, [eurUsd, price, unclaimedTokens]);

    const hasUnclaimedTokens = testUnclaimed(unclaimedTokens);
    const shouldGoThroughKYC = isKycRequired && (!kycSignature || kycStage === "started");

    useEffect(() => {
        if (!account) {
            setStage("connect");
        }

        if (active && chainId && account && stage === "connect") {
            setStage("network");
        }
    }, [stage, account, chainId, active]);

    useEffect(() => {
        if (stage === "network" && chainId === usedNetwork.chainId) {
            setStage("buy");
        }
    }, [stage, chainId, account]);

    useEffect(() => {
        if (stage === "buy" && chainId !== usedNetwork.chainId) {
            setStage("network");
        }
    }, [stage, chainId, account]);

    useEffect(() => {
        setErrorMessage("");
        if (state.status === "Exception" || state.status === "Fail") {
            if (state.errorMessage?.includes("insufficient funds")) {
                setErrorMessage("insufficient funds");
            }
        }
    }, [state]);

    useEffect(() => {
        const saved_email = localStorage.getItem("email");
        if (saved_email && account) {
            setEmail(saved_email);
            checkIfKYCCompleted(saved_email, account).then(response => {
                if ("r" in response) {
                    setKycStage("completed");
                    setStage("buy");
                    setKycSignature(response);
                } else {
                    setKycStage("started");
                    setKycSignature(null);
                }
            }).catch(() => { setKycSignature(null); setKycStage("started"); });
        }
    }, [account]);

    useEffect(() => {
        if (!affiliateCodeRaw) return;

        getAffiliateCode(affiliateCodeRaw).then(code => {
            if (code) {
                setAffiliateCode(code);
            }
        })
            .catch(() => { setAffiliateCode(null); console.error("Failed to get affilaite code"); });
    }, [affiliateCodeRaw]);

    const onClickButton = () => {
        switch (stage) {
            case "connect":
                activateBrowserWallet();
                break;
            case "network":
                if (library) {
                    addMumbai(library).then(() => switchNetwork(usedNetwork.chainId)).then(() => setStage("buy"));
                } else { setStage("connect"); }
                break;
            case "buy":
                if (paused || amountUSD < 9 || amountUSD > 10000) return;

                let sendFunction;
                if (buyMethod === "buyTokensWithKYCAndAffiliation") {
                    sendFunction = () => send(
                        kycSignature?.emailHash || "",
                        kycSignature?.r || "",
                        kycSignature?.s || "",
                        kycSignature?.v || 0,
                        affiliateCode?.influencerAddress || constants.AddressZero,
                        affiliateCode?.salePercent10000 || 0,
                        affiliateCode?.extraTokens10000 || 0,
                        affiliateCode?.r,
                        affiliateCode?.s,
                        affiliateCode?.v,
                        { value: amountMatic }
                    );
                } else if (buyMethod === "buyTokensWithKYC") {
                    sendFunction = () => send(
                        kycSignature?.emailHash,
                        kycSignature?.r,
                        kycSignature?.s,
                        kycSignature?.v,
                        { value: amountMatic }
                    );
                } else if (buyMethod === "buyTokensWithAffiliation") {
                    sendFunction = () => send(
                        affiliateCode?.influencerAddress || constants.AddressZero,
                        affiliateCode?.salePercent10000 || 0,
                        affiliateCode?.extraTokens10000 || 0,
                        affiliateCode?.r,
                        affiliateCode?.s,
                        affiliateCode?.v,
                        { value: amountMatic }
                    );
                } else {
                    sendFunction = () => send({ value: amountMatic });
                }

                sendFunction()
                    .then(() => logPurchase(email, amountMatic.toString(), account || ""))
                    .catch(reason => {
                        const msg: string = reason?.data?.message || "";
                        console.log("catch in purchase", msg);
                    });

                break;
        }
    };

    return <div className="flex flex-col w-full gap-2 align-middle justify-center">
        {stage !== "buy" && (
            <>
                <div className="relative mx-auto w-full md:w-1/2 lg:w-[45%] mt-8 lg:h-[7rem] flex items-center justify-center align-middle cursor-pointer" onClick={onClickButton}>
                    <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
                    <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                        <p className="w-full text-white font-bold text-2xl text-center uppercase select-none">
                            {stage === "connect" && "connect metamask"}
                            {stage === "network" && "switch network"}
                        </p>
                    </div>
                </div>

            </>
        )}
        {stage === "buy" && !paused && <>
            {affiliateCode?.extraTokens10000 &&
                <p className="text-white font-bold text-xl mt-2">Thanks to your affiliate link you are getting {(affiliateCode.extraTokens10000 / 100).toFixed(2)}% tokens extra!</p>}

            <p className="text-white font-bold text-4xl mt-6">BUY WITH MATIC</p>
            <BuyStageButton kyc={kycStage} sendTx={onClickButton} amount={amountMatic} email={email} amountUSD={amountUSD} />
            <AddToWallet />
            <p className="text-white text-base font-bold">
                Having issues? Contact support at{" "}
                <a href="mailto:admin@xvrprojectsystem.com" className="underline">admin@xvrprojectsystem.com</a>
                .
            </p>
        </>}
        {stage === "buy" && paused && <>
            <p className="text-white font-bold text-4xl mt-6">SALE PAUSED</p>
            <p className="text-white font-bold text-2xl mt-6">The sale of XVRCoin is currently paused. Please come back later and check if we're selling!</p>
        </>}

        {shouldGoThroughKYC && hasUnclaimedTokens && (
            <KYCSection
                email={email}
                setEmail={setEmail}

                onError={setErrorMessage}
                onKYCCompleted={(newSignature) => {
                    setKycStage("completed");
                    setKycSignature(newSignature);
                }}
            />
        )}
        {!shouldGoThroughKYC && hasUnclaimedTokens && (
            <ClaimSection wasKycRequired={isKycRequired} {...kycSignature} />
        )}

        <p className="text-red-600 text-3xl uppercase">{errorMessage ? `${errorMessage}` : ""}</p>
    </div >;
};

export default BuyButton;

const BuyStageButton: React.FC<{ amount: BigNumber, email: string, sendTx: () => void; kyc: KYCStage; amountUSD: number; }> = ({ amount, email, sendTx, kyc, amountUSD }) => {
    const { account } = useEthers();
    const maticBalance = useEtherBalance(account) || BigNumber.from("0");

    const canAfford = amount.lte(maticBalance);

    if (!account) return <></>;

    return <>
        <div
            className="relative mx-auto w-full md:w-1/2 lg:w-[45%] lg:h-[7rem] flex items-center align-middle justify-center cursor-pointer"
            onClick={sendTx}
        >
            <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
            <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                <p className={`w-full text-white font-bold text-2xl text-center uppercase select-none flex flex-col`}>
                    <span>Buy</span>
                    {
                        (amountUSD < 100 && <span className="text-sm text-red-300">min. $100</span>) ||
                        (amountUSD > 10000 && <span className="text-sm text-red-300">max. $10 000</span>) ||
                        (!canAfford && <span className="text-sm text-red-300">not enough funds</span>)
                    }
                </p>
            </div>
        </div>
        <HowToBuyButton />

        {amountUSD > 10000 &&
            <p className="text-white font-bold text-lg md:text-xl">For large purchases please contact <a className="underline text-red-300" href="mailto:info@xvrprojectsystem.com">info@xvrprojectsystem.com</a>.</p>
        }
    </>;
};

const selectBuyMethod = (hasKyc: boolean, hasAffiliate: boolean) => {
    if (hasKyc && hasAffiliate) return "buyTokensWithKYCAndAffiliation";
    if (hasKyc) return "buyTokensWithKYC";
    if (hasAffiliate) return "buyTokensWithAffiliation";
    return "buyTokens";
};