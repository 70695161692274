import { useCall } from "@usedapp/core";
import { FaLock } from "react-icons/fa";
import { airdropNfts, nft } from "../../util/constants";

interface Props {
    id: number;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onLargeClick?: React.MouseEventHandler<HTMLDivElement>;
    action?: string;
    available?: boolean;
    checkSold?: boolean;
    small?: boolean;
}

const GridItem: React.FC<Props> = ({ id, onClick, action = "buy", available = true, onLargeClick, checkSold = true, small = false }) => {
    const { value } = useCall({
        contract: nft,
        method: "exists",
        args: [id],
    }) || { value: [undefined] };

    const isSold = checkSold ? !!(value[0]) : false;
    const isAirdrop = !!airdropNfts[id.toString()];

    const imageId = id.toString().padStart(4, "0");
    const url = `https://xvrm.parlour.construction/a/${imageId}.webp`;

    const textSize = id > 999 ? "text-xl" : "text-[1.3rem]";
    const btnTextSize = id > 999 ? "text-sm px-2" : "text-base px-2";

    return <>
        <div className={`w-[150px] ${small ? "xl:w-[160px]" : "xl:w-[250px]"} aspect-square border-2 rounded-xl relative overflow-hidden cursor-pointer flex flex-col justify-end`}>
            <img
                className="w-full h-full object-cover absolute top-0 left-0"
                src={url}
                alt=""
            />

            <div
                className="absolute w-full h-1/2 bottom-0 left-0 bg-gradient-to-b from-[#00000000] to-[#000000]"
            />

            <div
                className="absolute w-full h-full"
                onClick={onLargeClick}
            />

            <div className="w-full flex flex-row justify-between items-end p-2">
                <span className={`text-white font-bold ${textSize} ml-1 relative z-30`}>#{id}</span>
                {
                    isAirdrop
                        ? <span className={`text-white cursor-not-allowed select-none bg-gray-600 py-1 rounded-lg font-bold ${btnTextSize} opacity-50 capitalize`}>airdrop</span>
                        : isSold
                            ? <span className={`text-white cursor-not-allowed select-none bg-gray-600 py-1 rounded-lg font-bold ${btnTextSize} opacity-50 capitalize`}>sold</span>
                            : <span className={`text-white cursor-pointer select-none bg-pinkMain py-1 rounded-lg font-bold opacity-80 ${btnTextSize} shadow-sm hover:shadow-2xl transition-all duration-200 capitalize flex flex-row justify-center items-center gap-1`} onClick={onClick}>{!available && <FaLock size={16} />} {action}</span>
                }
            </div>
        </div>
    </>;
};

export default GridItem;
