import { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "../../hooks/useMediaQuery";
import useIsVisible from "../../util/useIsVisible";
import { useNFTPaused } from "../../util/usePaused";
import GridItem from "./GridItem";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";

interface Props {
    onClick?: (id: number) => void;
}

const GridShowcase: React.FC<Props> = ({ onClick }) => {
    const [nfts, setNfts] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const visibleRef = useRef<HTMLDivElement>(null);
    const isVisible = useIsVisible(visibleRef);
    const wasVisible = useRef(false);

    const [open, setOpen] = useState<number | undefined>(undefined);
    const [showMore, setShowMore] = useState(false);

    const paused = useNFTPaused();

    const isDesktop = useMediaQuery("(min-width: 724px)");

    const loadMore = useCallback(() => {
        const newNfts = [...nfts];
        for (let i = 1; i <= 10; i++) {
            newNfts.push(nfts.length + i);
        }
        setNfts(newNfts);
    }, [nfts]);

    useEffect(() => {
        if (isVisible && !wasVisible.current) {
            loadMore();
        }

        wasVisible.current = isVisible;
    }, [isVisible, loadMore]);

    return <div className="w-full flex flex-col items-center gap-y-12 h-[80vh] max-h-[600px]">
        <div className="lg:w-[65rem] md:w-[45rem] w-[20rem] pt-24 overflow-x-hidden">
            <Swiper navigation={true} modules={[Navigation]} slidesPerView={isDesktop ? 3 : 1} className="mySwiper">
                {nfts.map((nft, index) =>
                    <SwiperSlide>
                        <div className="md:pl-10 pl-20">
                            <GridItem
                                key={index}
                                id={nft}
                                onLargeClick={() => setOpen(nft)}
                                available={nft === 1 ? true : !paused}
                                checkSold={nft === 1 ? false : true}
                                action="Preview"
                                onClick={(onClick && (!paused || nft === 1)) ? () => onClick(nft) : undefined}
                            />
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>

        <div ref={visibleRef} className="w-full h-1 bg-transparent"></div>

        <button
            onClick={() => setShowMore(true)}
            className="bg-pinkMain font-bold text-2xl text-white px-4 py-2 rounded-lg select-none cursor-pointer"
        >
            Show more
        </button>

        <ShowMore
            open={showMore}
            onExit={() => setShowMore(false)}
            onPreviewOpen={setOpen}
            onBuyClick={onClick}
        />
        <LargePreview id={open} onClick={() => setOpen(undefined)} />
    </div>;
};

export default GridShowcase;

const LargePreview: React.FC<{ id?: number; onClick: () => void; }> = ({ id, onClick }) => {
    if (!id) return null;

    const imageId = id.toString().padStart(4, "0");
    const url = `https://xvrm.parlour.construction/a/${imageId}.webp`;

    return <div
        className={`fixed top-0 left-0 w-full h-full bg-black z-50 flex flex-col justify-center items-center bg-opacity-50 p-12`}
        onClick={onClick}
    >
        <div className="w-[90%] md:w-[50%] aspect-square">
            <img src={url} alt="" className="w-full h-full object-contain rounded-3xl border-2 border-white" />
        </div>
    </div>;
};

const ShowMore: React.FC<{
    open: boolean;
    onExit: () => void;
    onPreviewOpen: (id: number) => void;
    onBuyClick?: (id: number) => void;
}> = ({ open, onExit, onPreviewOpen, onBuyClick }) => {
    const [nfts, setNfts] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const visibleRef = useRef<HTMLDivElement>(null);
    const isVisible = useIsVisible(visibleRef);
    const wasVisible = useRef(false);

    const paused = useNFTPaused();

    const loadMore = useCallback(() => {
        const newNfts = [...nfts];
        for (let i = 1; i <= 15; i++) {
            newNfts.push(nfts.length + i);
        }
        setNfts(newNfts);
    }, [nfts]);

    useEffect(() => {
        if (isVisible && !wasVisible.current) {
            loadMore();
        }

        wasVisible.current = isVisible;
    }, [isVisible, loadMore]);

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-black z-40 flex-col justify-center items-center bg-opacity-90 ${open ? "flex" : "hidden"}`}
            onClick={onExit}
        >
            <div className="w-[100%] h-[100%]">
                <div className="w-full h-full flex flex-col items-center gap-y-12 py-12 overflow-y-scroll">
                    <div className="mx-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 items-center justify-center align-middle content-center">
                        {nfts.map((nft, index) =>
                            <GridItem
                                key={index}
                                id={nft}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!onBuyClick || (paused && nft !== 1)) return;
                                    onBuyClick(nft);
                                }}
                                onLargeClick={(e) => {
                                    e.stopPropagation();
                                    onPreviewOpen(nft);
                                }}

                                small
                                available={nft === 1 ? true : !paused}
                                checkSold={nft === 1 ? false : true}
                                action={nft === 1 ? "Auction" : "Buy"}
                            />
                        )}

                        <div ref={visibleRef} className="w-full h-12 bg-transparent"></div>
                    </div>


                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            loadMore();
                        }}
                        className="bg-pinkMain font-bold text-2xl text-white px-4 py-2 rounded-lg select-none cursor-pointer"
                    >
                        Load more
                    </button>
                </div>
            </div>
        </div>
    );
};