import { useState } from "react";
import InfoModal from "./InfoModal";
import BuyNowButton from "../images/BuyNowButton.png";

export default function HowToBuyButton() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div
        className="relative mx-auto w-full md:w-1/2 lg:w-[45%] lg:h-[7rem] flex items-center align-middle justify-center cursor-pointer"
        onClick={() => {
          // window.open("https://metamask.zendesk.com/hc/en-us/articles/360058239311-Directly-buying-tokens-with-on-ramps-in-MetaMask", "_blank", "noopener,noreferrer");
          setOpen(true);
        }}
      >
        <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton}
        />
        <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
          <p className={`w-full text-white font-bold text-2xl text-center uppercase select-none flex flex-col`}>
            <span>How to buy?</span>
          </p>
        </div>
      </div>


      <InfoModal isOpen={isOpen} setOpen={setOpen}>
        <p className="text-white font-bold text-2xl">How to buy the XVR token?</p>
        <p>In order to buy the XVR token you will need to have a MetaMask wallet with some MATIC in it. Below you can learn how to create a wallet and buy MATIC that you can spend.</p>

        <div className="flex flex-col gap-4 w-full items-center font-bold text-2xl">
          <h1>How to install MetaMask?</h1>
          <div className="aspect-video w-full max-w-[400px] max-h-[30vh]">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/Af_lQ1zUnoM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <h1 className="mt-4">How to buy MATIC?</h1>
          <div className="aspect-video w-full max-w-[400px] max-h-[30vh]">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/EO1O8fPxv8M" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </InfoModal>
    </>
  );
}