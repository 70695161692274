import BuyNowButton from "../images/BuyNowButton.png";
import { useContractFunction } from "@usedapp/core";
import useUnclaimedTokens from "../util/useUnclaimedTokens";
import { constants, Contract } from "ethers";
import SaleABI from "../util/SaleABI.json";
import { prettyFormatEther } from "./KYCSection";
import { useEffect, useState } from "react";
import InfoModal from "./InfoModal";
import { AiOutlineQuestionCircle, AiOutlineTransaction } from "react-icons/ai";
import { addToken } from "./AddToWallet";

interface Props {
    emailHash?: string;
    r?: string;
    s?: string;
    v?: number;
    wasKycRequired: boolean;
}

const ClaimSection: React.FC<Props> = ({ emailHash, r, s, v, wasKycRequired }) => {
    const unclaimedTokens = useUnclaimedTokens();
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => window.scrollBy({ top: 400, behavior: "smooth" }), 200);
        return () => clearTimeout(timeout);
    }, []);

    const { send } = useContractFunction(new Contract("0x7dc713427BCB6a110fddAF29051EE078A92457EF", SaleABI), "claimTokens");

    return (
        <div className="flex flex-col items-center mt-6 mb-12 py-12 border-2 pulsateBorder rounded-2xl">
            <p className="text-white font-bold text-4xl mt-8">CLAIM TOKENS</p>
            <p className="text-white font-bold text-2xl my-6 mx-12 text-center">
                {wasKycRequired ? <>
                    You bought {prettyFormatEther(unclaimedTokens)} tokens before you completed the KYC procedure. To access your {unclaimedTokens && prettyFormatEther(unclaimedTokens)} XVR tokens you need to press the button below.
                </> : <>
                    You bought tokens for below €1000, so you don't need to complete a KYC procedure. You can claim your {unclaimedTokens && prettyFormatEther(unclaimedTokens)} XVR tokens by clicking the button below.
                </>}
            </p>

            <div
                className="relative mx-auto w-full md:w-1/2 lg:w-[45%] lg:h-[7rem] flex items-center justify-center align-middle cursor-pointer"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
                <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                    <p className="w-full text-white font-bold text-2xl text-center uppercase select-none">
                        claim
                    </p>
                </div>
            </div>

            <InfoModal isOpen={isOpen} setOpen={setOpen}>
                <p className="font-bold text-xl">
                    Your token purchase has been registered. For the tokens to be sent to your wallet, you will need to claim them manually.
                </p>

                <div className="flex flex-row items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                        <AiOutlineTransaction />
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <p>Press the below button to claim the tokens from our smart contract. No payment is required, but you will need a negligible amount for the gas fee.</p>

                    </div>
                </div>

                <button
                    className="py-1 px-4 bg-[#170017] border-2 border-white text-white rounded-md"
                    onClick={() => {
                        send(emailHash || constants.HashZero, r || constants.HashZero, s || constants.HashZero, v || 0);
                    }}
                >
                    Claim tokens
                </button>

                <div className="flex flex-row items-center gap-4">
                    <div className="w-[30px] h-[30px]">
                        <AiOutlineQuestionCircle />
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <p>If you claimed your tokens, but can't see them in your wallet, you might need to <button className="underline" onClick={addToken}>add them by clicking here</button>.</p>
                    </div>
                </div>
            </InfoModal>
        </div>
    );
};

export default ClaimSection;
