import { useCall } from "@usedapp/core";
import { BigNumber, constants, Contract } from "ethers";


const useEurUsd = (): BigNumber => {
    const { value } = useCall({
        contract: EurUsdOracle,
        args: [],
        method: "latestRoundData",
    }) ?? { value: ["", "0", "", "", ""] };

    try {
        return BigNumber.from(value[1]);
    } catch (e) {
        return constants.Zero;
    }
};

export default useEurUsd;

const OracleABI = [
    'function decimals() view returns (uint8)', // = 8
    'function description() view returns (string)', // "EUR / USD"
    'function latestRoundData() view returns (uint80, int256, uint256, uint256, uint80)',
    'function version() view returns (uint256)'
];

export const EurUsdOracle = new Contract("0x73366Fe0AA0Ded304479862808e02506FE556a98", OracleABI);