import useHasBoughtTokens from "../util/useHasBoughtTokens";
import { tokenAddress } from "../util/constants";

/**
 * Add the XVR token to the user's wallet
 */
export function addToken() {
  try {
    // @ts-ignore
    ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: "XVR",
          decimals: 18,
          image: "https://token.xvrprojectsystem.com/fv32.png",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export default function AddToWallet() {
  const hasBoughtTokens = useHasBoughtTokens();

  if (!hasBoughtTokens) return null;

  return (
    <div className="flex flex-row gap-2 items-center text-white text-base font-bold justify-center mt-4">
      <span>Can't see your tokens?</span>
      <button className="underline" onClick={addToken}>Add to Metamask.</button>
    </div>
  );
}