import { useCall, useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import { tokenSale } from "./constants";

const useUnclaimedTokens = () => {
    const { account } = useEthers();
    const { value } = useCall({
        contract: tokenSale,
        args: [account || "0x0000000000000000000000000000000000000000"],
        method: "tokensLeftToClaim"
    }) ?? { value: undefined };

    if (!value) return undefined;
    if (!BigNumber.isBigNumber(value[0])) return undefined;

    const amount = value[0] as BigNumber;
    return amount;
};

/**
 * Check if the user has unclaimed tokens
 * @param value The number returned by useUnclaimedTokens
 */
export const testUnclaimed = (value: BigNumber | undefined) => {
    if (!value) return false;
    else if (BigNumber.isBigNumber(value)) return value.gt(0);
    else return false;
};

export default useUnclaimedTokens;