import React from 'react';
import { IoLogoDiscord } from 'react-icons/io5';
import { FaYoutubeSquare, FaFacebookSquare, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';
import telegramicon from "../images/telegramicon.png";

function Footer() {
    return (
        <div className="font-poppins flex flex-col justify-center w-full h-auto">
            <div className="flex flex-row mt-12 justify-center space-x-2">
                <a rel="noreferrer" href='https://www.facebook.com/profile.php?id=100078143688034' target="_blank">
                    <FaFacebookSquare color='rgb(0 204 255)' size={48} className="text-neonBlue hover:scale-125 duration-300" />
                </a>
                <a rel="noreferrer" href='https://www.youtube.com/channel/UCUPy0HmCRhEn5af24o7nfDA' target="_blank">
                    <FaYoutubeSquare color='rgb(0 204 255)' size={48} className="text-neonBlue  hover:scale-125 duration-300" />
                </a>
                <a rel="noreferrer" href='https://www.instagram.com/xvrprojectsystem/' target="_blank">
                    <FaInstagramSquare color='rgb(0 204 255)' size={48} className="text-neonBlue hover:scale-125 duration-300" />
                </a>
                <a rel="noreferrer" href='https://twitter.com/home' target="_blank">
                    <FaTwitterSquare color='rgb(0 204 255)' size={48} className="text-neonBlue hover:scale-125 duration-300" />
                </a>
                <a rel="noreferrer" href='https://discord.com/invite/tBX3TmSEg4' target="_blank">
                    <IoLogoDiscord color='rgb(0 204 255)' size={48} className="text-neonBlue hover:scale-125 duration-300 mt-0.5" />
                </a>
                <a rel="noreferrer" href='https://t.me/+YqXWY7lG6Y5mMDg8' target="_blank">
                    <img alt="" src={telegramicon} className="w-[40px] h-[40px] mt-1 hover:scale-125 duration-300 " />
                </a>
            </div>
            <div className="flex text-neonBlue  flex-row mt-6 mb-12 justify-center space-x-12">
                <div className='text-left'>XVR ProjectSystem OÜ <br />
                    Lõõtsa tn 5 <br />
                    11415 Tallinn, Estonin.</div>
                <a rel="noreferrer" href='https://xvrprojectsystem.com/privacy-policy' target="_blank">
                    <div>Privacy Policy</div>
                </a>
            </div>
        </div >
    );
}

export default Footer;
