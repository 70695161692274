import React from 'react';
import BuyNowButton from "../images/BuyNowButton.png";

function LandingSection(props: { isNFTon: boolean; }) {
    const { isNFTon } = props;

    return (
        <div>
            <div className="mx-auto w-[75%] lg:w-[55%] 2xl:w-[35%]">
                <div className="mb-8 xl:mb-0 mt-14 text-neonBlue text-left w-[95%] mx-auto">
                    {isNFTon ?
                        (
                            <>
                                <a href="http://hotnftstars.io/" rel="noreferrer" target='_blank'>
                                    <div
                                        className="relative mx-auto w-full md:w-1/2 lg:w-[85%] lg:h-[7rem] flex items-center align-middle justify-center cursor-pointer">
                                        <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
                                        <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                                            <p className={`w-full text-white font-bold lg:text-2xl text-sm text-center uppercase select-none flex flex-col`}>
                                                <span>Get to know more info about HOT NFT Stars</span>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </>
                        ) : (
                            <>
                                <a href="https://xvrprojectsystem.com/" rel="noreferrer" target='_blank'>
                                    <div
                                        className="relative mx-auto w-full md:w-1/2 lg:w-[85%] lg:h-[7rem] flex items-center align-middle justify-center cursor-pointer">
                                        <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
                                        <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                                            <p className={`w-full text-white font-bold lg:text-2xl text-sm text-center uppercase select-none flex flex-col`}>
                                                <span>Get to know more info about XVR Coin</span>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default LandingSection;
