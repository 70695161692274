import { useCall } from "@usedapp/core";
import { BigNumber } from "ethers";
import { tokenSale } from "./constants";

/**
 * @returns Whether the user has bought any tokens
 */
const useExtraTokens = () => {
  const { value } = useCall({
    contract: tokenSale,
    args: [],
    method: "extraTokensNumerator",
  }) ?? { value: undefined };

  if (!value) return 0;
  if (!BigNumber.isBigNumber(value[0])) return 0;

  const amount = value[0] as BigNumber;
  return amount.toNumber() / 100;
};

export default useExtraTokens;
