import { Contract } from "ethers";
import NftABI from "./NFTABI.json";
import NftSaleABI from "./NFTSaleABI.json";
import SaleABI from "./SaleABI.json";

export const nftAddress = "0x9c8A30F7dBC97951104fAFCfe85C9c50A7ECB34F";
export const nftSaleAddress = "0xCC89bB34323463199F08Ca5C55ffc6f1c3e92d96";
export const tokenSaleAddress = "0x7dc713427BCB6a110fddAF29051EE078A92457EF";
export const tokenAddress = "0xEbb21b995f59D2b7E2e1aaE8a0c14093a541965c";

export const nft = new Contract(nftAddress, NftABI);
export const nftSale = new Contract(nftSaleAddress, NftSaleABI);
export const tokenSale = new Contract(tokenSaleAddress, SaleABI);

/**
 * Ids of NFTs that will be airdropped to users instead of sold
 */
export const airdropNfts: {
  [key: string]: boolean | undefined;
} = {
  "10": true,
  "12": true,
  "14": true,
  "20": true,
  "22": true,
  "23": true,
  "26": true,
  "28": true,
  "37": true,
  "40": true,
  "100": true,
  "109": true,
  "112": true,
  "120": true,
  "151": true,
  "166": true,
  "175": true,
  "177": true,
  "179": true,
  "183": true,
  "186": true,
  "188": true,
  "197": true,
  "211": true,
  "223": true,
  "235": true,
  "240": true,
  "247": true,
  "253": true,
  "261": true,
  "271": true,
  "280": true,
  "286": true,
  "291": true,
  "295": true,
  "301": true,
  "307": true,
  "331": true,
  "343": true,
  "348": true,
  "360": true,
  "391": true,
  "418": true,
  "424": true,
  "426": true,
  "432": true,
  "436": true,
  "452": true,
  "472": true,
  "496": true,
  "510": true,
  "529": true,
  "532": true,
  "537": true,
  "544": true,
  "547": true,
  "553": true,
  "559": true,
  "565": true,
  "582": true,
  "598": true,
  "599": true,
  "601": true,
  "604": true,
  "627": true,
  "648": true,
  "664": true,
  "670": true,
  "691": true,
  "705": true,
  "722": true,
  "727": true,
  "766": true,
  "769": true,
  "772": true,
  "780": true,
  "787": true,
  "808": true,
  "821": true,
  "844": true,
  "865": true,
  "867": true,
  "874": true,
  "876": true,
  "877": true,
  "879": true,
  "886": true,
  "925": true,
  "927": true,
  "931": true,
  "946": true,
  "952": true,
  "961": true,
  "970": true,
  "982": true,
  "988": true,
  "1015": true,
  "1020": true,
  "1036": true,
  "1042": true,
  "1051": true,
  "1057": true,
  "1058": true,
  "1065": true,
  "1096": true,
  "1123": true,
  "1126": true,
  "1136": true,
  "1165": true,
  "1168": true,
  "1180": true,
  "1208": true,
  "1210": true,
  "1219": true,
  "1231": true,
  "1235": true,
  "1236": true,
  "1240": true,
  "1260": true,
  "1267": true,
  "1311": true,
  "1312": true,
  "1322": true,
  "1333": true,
  "1369": true,
  "1381": true,
  "1387": true,
  "1402": true,
  "1408": true,
  "1414": true,
  "1416": true,
  "1418": true,
  "1422": true,
  "1426": true,
  "1428": true,
  "1433": true,
  "1435": true,
  "1438": true,
  "1458": true,
  "1471": true,
  "1477": true,
  "1483": true,
  "1484": true,
  "1509": true,
  "1513": true,
  "1519": true,
  "1527": true,
  "1537": true,
  "1549": true,
  "1561": true,
  "1571": true,
  "1586": true,
  "1612": true,
  "1616": true,
  "1621": true,
  "1627": true,
  "1639": true,
  "1681": true,
  "1689": true,
  "1693": true,
  "1696": true,
  "1702": true,
  "1708": true,
  "1774": true,
  "1782": true,
  "1798": true,
  "1801": true,
  "1819": true,
  "1855": true,
  "1873": true,
  "1876": true,
  "1879": true,
  "1880": true,
  "1882": true,
  "1884": true,
  "1897": true,
  "1900": true,
  "1912": true,
  "1915": true,
  "1957": true,
  "1958": true,
  "1963": true,
  "1988": true,
  "1996": true,
  "2020": true,
  "2055": true,
  "2095": true,
  "2101": true,
  "2114": true,
  "2124": true,
  "2128": true,
  "2141": true,
  "2145": true,
  "2148": true,
  "2152": true,
  "2154": true,
  "2158": true,
  "2160": true,
  "2162": true,
  "2172": true,
  "2176": true,
  "2203": true,
  "2213": true,
  "2215": true,
  "2221": true,
  "2227": true,
  "2233": true,
  "2243": true,
  "2245": true,
  "2257": true,
  "2275": true,
  "2284": true,
  "2323": true,
  "2338": true,
  "2346": true,
  "2350": true,
  "2360": true,
  "2377": true,
  "2386": true,
  "2389": true,
  "2401": true,
  "2437": true,
  "2440": true,
  "2455": true,
  "2473": true,
  "2481": true,
  "2491": true,
  "2498": true,
  "2500": true,
  "2502": true,
  "2503": true,
  "2509": true,
  "2565": true,
  "2569": true,
  "2584": true,
  "2589": true,
  "2592": true,
  "2593": true,
  "2644": true,
  "2647": true,
  "2653": true,
  "2674": true,
  "2675": true,
  "2683": true,
  "2689": true,
  "2700": true,
  "2707": true,
  "2709": true,
  "2711": true,
  "2716": true,
  "2719": true,
  "2722": true,
  "2726": true,
  "2729": true,
  "2733": true,
  "2736": true,
  "2739": true,
  "2741": true,
  "2747": true,
  "2748": true,
  "2776": true,
  "2780": true,
  "2781": true,
  "2788": true,
  "2795": true,
  "2805": true,
  "2815": true,
  "2833": true,
  "2845": true,
  "2857": true,
  "2881": true,
  "2893": true,
  "2920": true,
  "2925": true,
  "2938": true,
  "2951": true,
  "2953": true,
  "2956": true,
  "2965": true,
  "3000": true,
  "3003": true,
  "3004": true,
  "3025": true,
  "3037": true,
  "3040": true,
  "3043": true,
  "3052": true,
  "3078": true,
  "3086": true,
  "3088": true,
  "3100": true,
  "3103": true,
  "3106": true,
  "3145": true,
  "3166": true,
  "3179": true,
  "3181": true,
  "3185": true,
  "3187": true,
  "3191": true,
  "3210": true,
  "3217": true,
  "3229": true,
  "3234": true,
  "3247": true,
  "3281": true,
  "3289": true,
  "3308": true,
  "3317": true,
  "3324": true,
  "3331": true,
  "3340": true,
  "3353": true,
  "3374": true,
  "3381": true,
  "3399": true,
  "3402": true,
  "3406": true,
  "3412": true,
  "3413": true,
  "3416": true,
  "3421": true,
  "3423": true,
  "3424": true,
  "3427": true,
  "3429": true,
  "3433": true,
  "3435": true,
  "3438": true,
  "3439": true,
  "3441": true,
  "3445": true,
  "3474": true,
  "3477": true,
  "3486": true,
  "3490": true,
  "3498": true,
  "3502": true,
  "3509": true,
  "3520": true,
  "3526": true,
  "3547": true,
  "3551": true,
  "3556": true,
  "3565": true,
  "3577": true,
  "3598": true,
  "3603": true,
  "3624": true,
  "3625": true,
  "3630": true,
  "3631": true,
  "3640": true,
  "3697": true,
  "3716": true,
  "3718": true,
  "3721": true,
  "3732": true,
  "3751": true,
  "3754": true,
  "3766": true,
  "3776": true,
  "3778": true,
  "3786": true,
  "3790": true,
  "3792": true,
  "3796": true,
  "3799": true,
  "3802": true,
  "3804": true,
  "3805": true,
  "3819": true,
  "3820": true,
  "3826": true,
  "3837": true,
  "3841": true,
  "3843": true,
  "3847": true,
  "3853": true,
  "3859": true,
  "3870": true,
  "3877": true,
  "3889": true,
  "3895": true,
  "3901": true,
  "3904": true,
  "3910": true,
  "3913": true,
  "3928": true,
  "3937": true,
  "3940": true,
  "3941": true,
  "3946": true,
  "3949": true,
  "3955": true,
  "3958": true,
  "3961": true,
  "3964": true,
  "3970": true,
  "3988": true,
};

export const lotteryIds = [
  10,
  12,
  14,
  16,
  20,
  22,
  23,
  24,
  26,
  28,
  32,
  37,
  39,
  40,
  46,
  55,
  62,
  76,
  88,
  97,
  100,
  106,
  109,
  110,
  112,
  117,
  120,
  128,
  145,
  148,
  149,
  151,
  159,
  161,
  166,
  167,
  175,
  177,
  179,
  181,
  182,
  183,
  186,
  188,
  197,
  198,
  208,
  209,
  211,
  218,
  220,
  222,
  223,
  226,
  230,
  235,
  236,
  237,
  240,
  241,
  247,
  250,
  253,
  256,
  257,
  260,
  261,
  266,
  271,
  277,
  280,
  286,
  291,
  293,
  295,
  297,
  301,
  307,
  309,
  310,
  319,
  325,
  328,
  329,
  331,
  334,
  335,
  343,
  348,
  360,
  366,
  367,
  374,
  386,
  389,
  390,
  391,
  392,
  393,
  397,
  403,
  404,
  407,
  410,
  417,
  418,
  424,
  426,
  432,
  435,
  436,
  438,
  443,
  448,
  451,
  452,
  455,
  461,
  472,
  473,
  477,
  480,
  482,
  483,
  489,
  496,
  498,
  499,
  505,
  506,
  510,
  512,
  514,
  527,
  529,
  532,
  533,
  535,
  537,
  540,
  544,
  547,
  553,
  556,
  559,
  560,
  562,
  565,
  567,
  572,
  575,
  582,
  583,
  587,
  596,
  598,
  599,
  601,
  603,
  604,
  605,
  608,
  609,
  611,
  617,
  627,
  631,
  641,
  643,
  648,
  653,
  656,
  664,
  666,
  670,
  671,
  673,
  689,
  691,
  692,
  698,
  705,
  714,
  720,
  721,
  722,
  723,
  727,
  728,
  729,
  733,
  737,
  745,
  749,
  753,
  766,
  769,
  771,
  772,
  780,
  787,
  790,
  793,
  808,
  820,
  821,
  824,
  825,
  826,
  828,
  833,
  839,
  840,
  844,
  848,
  850,
  853,
  855,
  865,
  867,
  871,
  874,
  876,
  877,
  879,
  886,
  895,
  913,
  920,
  925,
  927,
  931,
  937,
  939,
  942,
  946,
  951,
  952,
  956,
  961,
  962,
  970,
  974,
  975,
  976,
  979,
  982,
  985,
  987,
  988,
  1002,
  1006,
  1015,
  1020,
  1021,
  1022,
  1023,
  1036,
  1037,
  1040,
  1042,
  1051,
  1054,
  1057,
  1058,
  1060,
  1065,
  1066,
  1079,
  1085,
  1091,
  1093,
  1096,
  1122,
  1123,
  1126,
  1127,
  1134,
  1136,
  1141,
  1143,
  1165,
  1168,
  1170,
  1171,
  1180,
  1183,
  1184,
  1189,
  1197,
  1208,
  1210,
  1213,
  1219,
  1220,
  1231,
  1235,
  1236,
  1237,
  1238,
  1240,
  1247,
  1255,
  1260,
  1265,
  1267,
  1272,
  1303,
  1305,
  1310,
  1311,
  1312,
  1314,
  1322,
  1329,
  1330,
  1333,
  1336,
  1339,
  1343,
  1344,
  1358,
  1366,
  1369,
  1375,
  1381,
  1383,
  1387,
  1390,
  1395,
  1402,
  1408,
  1414,
  1416,
  1418,
  1422,
  1424,
  1426,
  1427,
  1428,
  1433,
  1434,
  1435,
  1438,
  1445,
  1452,
  1458,
  1465,
  1467,
  1471,
  1476,
  1477,
  1483,
  1484,
  1489,
  1490,
  1498,
  1499,
  1509,
  1510,
  1513,
  1519,
  1523,
  1527,
  1532,
  1537,
  1548,
  1549,
  1550,
  1553,
  1561,
  1562,
  1566,
  1571,
  1577,
  1584,
  1586,
  1587,
  1588,
  1591,
  1595,
  1596,
  1604,
  1609,
  1612,
  1613,
  1615,
  1616,
  1620,
  1621,
  1627,
  1632,
  1637,
  1639,
  1641,
  1654,
  1655,
  1657,
  1658,
  1671,
  1675,
  1677,
  1679,
  1680,
  1681,
  1683,
  1689,
  1693,
  1696,
  1699,
  1702,
  1704,
  1707,
  1708,
  1713,
  1717,
  1718,
  1719,
  1720,
  1722,
  1728,
  1734,
  1735,
  1738,
  1745,
  1746,
  1752,
  1758,
  1761,
  1765,
  1768,
  1773,
  1774,
  1778,
  1780,
  1782,
  1785,
  1788,
  1798,
  1800,
  1801,
  1806,
  1810,
  1819,
  1831,
  1832,
  1834,
  1839,
  1845,
  1851,
  1852,
  1855,
  1857,
  1873,
  1874,
  1876,
  1879,
  1880,
  1882,
  1884,
  1886,
  1897,
  1900,
  1911,
  1912,
  1914,
  1915,
  1918,
  1932,
  1935,
  1941,
  1945,
  1950,
  1953,
  1955,
  1957,
  1958,
  1960,
  1963,
  1969,
  1971,
  1973,
  1975,
  1988,
  1995,
  1996,
  2019,
  2020,
  2025,
  2036,
  2045,
  2055,
  2057,
  2059,
  2060,
  2061,
  2063,
  2070,
  2074,
  2087,
  2095,
  2098,
  2099,
  2101,
  2114,
  2119,
  2124,
  2128,
  2130,
  2131,
  2137,
  2141,
  2145,
  2148,
  2152,
  2154,
  2158,
  2160,
  2161,
  2162,
  2164,
  2171,
  2172,
  2175,
  2176,
  2184,
  2188,
  2189,
  2190,
  2193,
  2201,
  2203,
  2209,
  2211,
  2213,
  2214,
  2215,
  2218,
  2221,
  2226,
  2227,
  2233,
  2243,
  2245,
  2257,
  2259,
  2266,
  2267,
  2269,
  2273,
  2275,
  2279,
  2280,
  2284,
  2286,
  2289,
  2292,
  2294,
  2306,
  2316,
  2323,
  2329,
  2333,
  2338,
  2343,
  2346,
  2348,
  2350,
  2358,
  2360,
  2365,
  2369,
  2373,
  2377,
  2383,
  2386,
  2389,
  2391,
  2392,
  2393,
  2395,
  2396,
  2401,
  2403,
  2408,
  2410,
  2418,
  2419,
  2437,
  2440,
  2453,
  2455,
  2460,
  2466,
  2469,
  2472,
  2473,
  2478,
  2481,
  2487,
  2491,
  2493,
  2498,
  2500,
  2502,
  2503,
  2505,
  2509,
  2511,
  2514,
  2526,
  2543,
  2548,
  2555,
  2564,
  2565,
  2569,
  2576,
  2580,
  2582,
  2584,
  2589,
  2590,
  2592,
  2593,
  2594,
  2597,
  2603,
  2612,
  2616,
  2618,
  2620,
  2625,
  2629,
  2644,
  2647,
  2650,
  2653,
  2656,
  2662,
  2665,
  2668,
  2670,
  2671,
  2674,
  2675,
  2677,
  2683,
  2689,
  2693,
  2695,
  2700,
  2701,
  2704,
  2706,
  2707,
  2709,
  2710,
  2711,
  2715,
  2716,
  2719,
  2722,
  2723,
  2726,
  2729,
  2733,
  2734,
  2735,
  2736,
  2739,
  2741,
  2743,
  2745,
  2747,
  2748,
  2749,
  2763,
  2765,
  2770,
  2776,
  2778,
  2780,
  2781,
  2784,
  2785,
  2788,
  2789,
  2792,
  2795,
  2797,
  2805,
  2807,
  2811,
  2812,
  2815,
  2822,
  2827,
  2833,
  2835,
  2837,
  2845,
  2852,
  2857,
  2859,
  2864,
  2873,
  2875,
  2880,
  2881,
  2883,
  2889,
  2893,
  2895,
  2906,
  2919,
  2920,
  2925,
  2936,
  2938,
  2946,
  2948,
  2950,
  2951,
  2953,
  2956,
  2961,
  2964,
  2965,
  2970,
  2974,
  2986,
  2989,
  2999,
  3000,
  3003,
  3004,
  3005,
  3010,
  3025,
  3026,
  3032,
  3033,
  3035,
  3037,
  3040,
  3043,
  3047,
  3049,
  3050,
  3052,
  3053,
  3070,
  3078,
  3086,
  3088,
  3100,
  3103,
  3106,
  3123,
  3129,
  3137,
  3140,
  3144,
  3145,
  3148,
  3151,
  3152,
  3153,
  3155,
  3160,
  3162,
  3166,
  3167,
  3178,
  3179,
  3181,
  3184,
  3185,
  3187,
  3191,
  3200,
  3208,
  3210,
  3217,
  3219,
  3229,
  3233,
  3234,
  3239,
  3241,
  3243,
  3247,
  3252,
  3253,
  3259,
  3261,
  3262,
  3263,
  3277,
  3281,
  3283,
  3284,
  3286,
  3289,
  3296,
  3299,
  3308,
  3317,
  3324,
  3325,
  3327,
  3331,
  3337,
  3340,
  3342,
  3353,
  3357,
  3360,
  3366,
  3367,
  3369,
  3371,
  3374,
  3378,
  3381,
  3383,
  3390,
  3396,
  3399,
  3402,
  3404,
  3406,
  3408,
  3409,
  3412,
  3413,
  3415,
  3416,
  3421,
  3423,
  3424,
  3427,
  3429,
  3433,
  3434,
  3435,
  3438,
  3439,
  3440,
  3441,
  3444,
  3445,
  3450,
  3453,
  3461,
  3463,
  3466,
  3471,
  3474,
  3475,
  3477,
  3479,
  3481,
  3483,
  3486,
  3487,
  3490,
  3492,
  3493,
  3494,
  3498,
  3502,
  3509,
  3514,
  3519,
  3520,
  3524,
  3526,
  3530,
  3531,
  3547,
  3550,
  3551,
  3552,
  3554,
  3556,
  3557,
  3559,
  3560,
  3565,
  3576,
  3577,
  3596,
  3597,
  3598,
  3600,
  3603,
  3616,
  3624,
  3625,
  3630,
  3631,
  3638,
  3640,
  3644,
  3651,
  3656,
  3659,
  3666,
  3677,
  3679,
  3680,
  3690,
  3697,
  3712,
  3716,
  3718,
  3721,
  3722,
  3723,
  3732,
  3742,
  3745,
  3748,
  3750,
  3751,
  3752,
  3754,
  3766,
  3773,
  3776,
  3778,
  3786,
  3790,
  3792,
  3796,
  3799,
  3800,
  3802,
  3804,
  3805,
  3811,
  3819,
  3820,
  3822,
  3826,
  3827,
  3837,
  3838,
  3841,
  3843,
  3846,
  3847,
  3849,
  3853,
  3856,
  3859,
  3861,
  3865,
  3869,
  3870,
  3871,
  3877,
  3887,
  3888,
  3889,
  3895,
  3899,
  3900,
  3901,
  3904,
  3908,
  3910,
  3911,
  3913,
  3928,
  3937,
  3940,
  3941,
  3945,
  3946,
  3949,
  3952,
  3955,
  3958,
  3961,
  3963,
  3964,
  3970,
  3978,
  3979,
  3980,
  3982,
  3983,
  3984,
  3988,
  3991,
  3999,
];