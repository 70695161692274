import { Polygon, useContractFunction, useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BuyNowButton from "../images/BuyNowButton.png";
import { NFTCode, getNftAffiliateCode } from "../util/api";
import { nftSale } from "../util/constants";
import useNFTPrice from "../util/useNFTPrice";
import { prettyFormatEther } from "./KYCSection";
import BidSection from "./nft/BidSection";
import GridShowcase from "./nft/GridShowcase";

export const usedNetwork = Polygon;
type PurchaseStage = "connect" | "network" | "buy";

const NFTBuySection: React.FC = () => {
    return (
        <div className="flex flex-col">
            <NFTBuyButton />
        </div>
    );
};

export const addNetwork = (lib: ethers.providers.JsonRpcProvider) => {
    return lib.send("wallet_addEthereumChain", [
        {
            chainId: `0x${usedNetwork.chainId.toString(16)}`,
            chainName: usedNetwork.chainName,
            nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com/"],
        }
    ]);
};

const NFTBuyButton: React.FC = () => {
    const [stage, setStage] = useState<PurchaseStage>("connect");

    const [affiliateCode, setAffiliateCode] = useState<NFTCode | null>(null);
    const { code: affiliateCodeRaw } = useParams();

    const [errorMessage, setErrorMessage] = useState("");
    const { active, account, chainId } = useEthers();
    const { state } = useContractFunction(nftSale, affiliateCode ? "buyAffiliate" : "buy");

    const price = useNFTPrice(); // usd

    useEffect(() => {
        if (!account) {
            setStage("connect");
        }

        if (active && chainId && account && stage === "connect") {
            setStage("network");
        }

        if (stage === "network" && chainId === usedNetwork.chainId) {
            setStage("buy");
        }
    }, [stage, account, chainId, active]);

    useEffect(() => {
        setErrorMessage("");
        if (state.status === "Exception" || state.status === "Fail") {
            if (state.errorMessage?.includes("insufficient funds")) {
                setErrorMessage("insufficient funds");
            }
        }
    }, [state]);

    useEffect(() => {
        if (!affiliateCodeRaw) return;

        getNftAffiliateCode(affiliateCodeRaw).then(code => {
            if (code) {
                setAffiliateCode(code);
            }
        })
            .catch(() => { setAffiliateCode(null); console.error("Failed to get affilaite code"); });
    }, [affiliateCodeRaw]);

    const onClickButton = (id: number) => {
        if (id === 1) {
            window.open(
                `https://opensea.io/assets/matic/0x9c8a30f7dbc97951104fafcfe85c9c50a7ecb34f/${id}`,
                "_blank",
                "noopener,noreferrer"
            );
            return;
        }

        window.open(
            "https://godsofsex.io",
            "_blank",
            "noopener,noreferrer"
        );
    };

    return <div className="flex flex-col w-full gap-2 align-middle justify-center">

        <p className="text-white font-bold text-4xl mt-24 mb-3">BID ON A UNIQUE GENESIS NFT</p>
        <BidSection />

        <p className="text-white font-bold text-4xl mt-24 mb-3">BUY FOR {prettyFormatEther(price)} USD (in MATIC)</p>
        <GridShowcase onClick={onClickButton} />

        {stage !== "buy" && <div className="relative mx-auto w-full md:w-1/2 lg:w-[80%] lg:max-w-[450px] m-8 lg:h-[7rem] flex items-center align-middle cursor-pointer" onClick={() => onClickButton(0)}>
            <img className="w-full h-full object-contain" alt="" src={BuyNowButton} />
            <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                <p className="w-full text-white font-bold text-2xl text-center uppercase select-none">
                    {stage === "connect" && "connect metamask"}
                    {stage === "network" && "switch network"}
                </p>
            </div>
        </div>}

        <p className="text-red-600 text-3xl font-semibold uppercase">{errorMessage ? `${errorMessage}` : ""}</p>
    </div >;
};

export default NFTBuySection;