import { useCoingeckoPrice } from "@usedapp/coingecko";
import { constants } from "ethers";
import { formatEther, parseEther } from 'ethers/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import BracketLeft from "../images/BracketBottomSmallLeft.png";
import BracketRight from "../images/BracketBottomSmallRight.png";
import useEurUsd from "../util/useEurUsd";
import useExtraTokens from '../util/useExtraTokens';
import useTokenPrice from '../util/useTokenPrice';
import useUnclaimedTokens from "../util/useUnclaimedTokens";
import BuyButton from './ButtonSector';
import HowToBuyButton from "./HowToBuyButton";
import NFTBuySection from './NFTBuySection';

function BuySection(props: { isNFTon: boolean; }) {
    const { isNFTon } = props;
    const [amount, setAmount] = useState("20200");
    const [amountMaticBN, setAmountMaticBN] = useState(() => parseEther("20200"));
    const [amountMatic, setAmountMatic] = useState("5.0");
    const [amountUSD, setAmountUSD] = useState("4.21");

    const [inputClicked, setInputClicked] = useState(false);

    const maticPrice = parseFloat(useCoingeckoPrice("matic-network", "usd") || "0");
    const TOKENS_PER_USD = useTokenPrice() || 0;

    const extraTokens = useExtraTokens();

    const unclaimedTokens = useUnclaimedTokens() || constants.Zero;
    const eurUsd = useEurUsd();
    const willKycBeRequired = useMemo(() => {
        if (TOKENS_PER_USD === 0 || eurUsd.eq(constants.Zero)) return false;
        try {
            const purchasedUsd = unclaimedTokens.div(TOKENS_PER_USD);
            const toPurchaseUsd = parseEther(amount).div(TOKENS_PER_USD);
            return purchasedUsd.add(toPurchaseUsd).div(eurUsd).div("10000000000").gte(1000);
        } catch (e) {
            return false;
        }
    }, [eurUsd, unclaimedTokens, TOKENS_PER_USD, amount]);

    useEffect(() => {
        try {
            let newAmountClean = amount.replaceAll(",", ".").replaceAll(" ", "");
            const newAmountBI = parseEther(newAmountClean);

            const newUSD = newAmountBI.div(TOKENS_PER_USD);
            {
                let newUSDString = formatEther(newUSD).split(".");
                newUSDString[1] = newUSDString[1]?.slice(0, 2);
                setAmountUSD(newUSDString.join("."));
            }

            {
                const maticPriceE6 = (maticPrice * 1000000).toFixed(0);
                const newMatic = newUSD.mul(1000000).div(maticPriceE6);

                setAmountMaticBN(newMatic);

                let newMaticString = formatEther(newMatic).split(".");
                if (newMaticString[1]) newMaticString[1] = newMaticString[1].slice(0, 2);

                setAmountMatic(newMaticString.join("."));
            }

        } catch (e) {
            setAmountMatic("0");
            setAmountUSD("0");
        }
    }, [amount, TOKENS_PER_USD, maticPrice]);

    return (<div className='h-auto flex  w-full justify-center md:mt-16 mt-24'>
        <div className="h-auto w-[90%] sm:w-[80%] max-w-[1000px] flex flex-col items-center">
            <p className="text-pinkMain md:text-[3rem] text-[2rem] font-neuropol" >WHICH ONE DO YOU WANT TO INVEST IN?</p>
            <div className="w-full flex md:flex-row flex-col mt-8">
                <Link to="/" className=" w-full md:w-[50%]">
                    {isNFTon ? (<div className="bg-neonBlue shadow-lg mx-10 flex justify-center items-center py-5 rounded-xl font-neuropol text-xl">NFT</div>) : (<div className="bg-transparent border-2 border-neonBlue mx-10 flex justify-center items-center py-5 rounded-xl font-neuropol text-white text-xl">NFT</div>)}
                </Link>
                <Link to="/t" className=" w-full md:w-[50%] md:pt-0 pt-5">
                    {isNFTon ? (<div className="bg-transparent border-2 border-neonBlue mx-10 flex justify-center items-center py-5 rounded-xl font-neuropol text-white text-xl">XVR Coin</div>) : (<div className="bg-neonBlue shadow-lg mx-10 flex justify-center items-center py-5 rounded-xl font-neuropol text-xl">XVR Coin</div>)}
                </Link>
            </div>

            {isNFTon ? (<div className='w-full flex flex-col items-center justify-center pt-20'>
                <HowToBuyButton />
                <NFTBuySection />
            </div>) : (
                <div className='flex flex-col items-center mt-6 w-full'>
                    <span className="h-10"></span>
                    <HowToBuyButton />
                    <span className="h-10"></span>
                    <p className='text-white text-xl font-bold'>Payment in MATIC only</p>
                    {willKycBeRequired ? (
                        <p className={`text-white text-xl font-bold underline`}>You will have to go through KYC</p>
                    ) : (
                        <p className={`text-white text-xl font-bold`}>Your purchase is below 1000€, KYC will NOT be required</p>
                    )}
                    <div className='relative w-full flex flex-col-reverse lg:flex-row items-center justify-center'>
                        <div className="flex lg:flex-row items-center justify-center">
                            <input
                                type="text"
                                placeholder={"10.00"}
                                pattern="^-?[0-9]\d*\.?\d*$"
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                                onClick={() => setInputClicked(true)}
                                className={`text-pinkMain h-auto md:w-[35%] md:ml-44 max-w-[7em] md:text-[4rem] text-[2rem] font-bold bg-transparent text-center outline-0 ring-0 mt-3 mb-6
                            border-2 ${inputClicked ? "border-opacity-0" : "border-opacity-100"} rounded-xl border-white border-dashed
                            !box-content
                            transition-all duration-300 ease-in-out`}
                            />
                            <p className="text-neonBlue text-2xl font-bold md:text-[4rem]">XVRC</p>
                        </div>

                        {!false && (
                            <div className='lg:absolute xl:left-[5%] lg:left-0 lg:gap-12 pt-8 lg:pt-0 flex flex-col lg:flex-row items-center text-white'>
                                <p>
                                    Enter how many<br />XVRcoins you want to buy
                                </p>
                                <AiOutlineArrowRight className='lg:block hidden' />
                                <AiOutlineArrowDown className='lg:hidden block' />
                            </div>
                        )}
                    </div>
                    <div className='-mt-2 flex flex-row gap-2'>
                        {parseFloat(amountUSD) > 1005 && (
                            <p className="text-green-400 text-2xl font-bold">(+ {
                                (parseInt(amount) * extraTokens / 100).toFixed(2)
                            } XVRC bonus)</p>
                        )}
                    </div>
                    <p className='text-white text-xl'>{amountMatic} MATIC ≈ {amountUSD} USD</p>
                    <BuyButton amountMatic={amountMaticBN} amountUSD={parseFloat(amountUSD)} />
                </div>
            )}


            <div className="w-full flex flex-row mb-6" >
                <img src={BracketLeft} alt="" className="w-[50%]" />
                <img src={BracketRight} alt="" className="w-[50%]" />
            </div>
        </div >
    </div>
    );
}

export default BuySection;
