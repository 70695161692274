import { useEthers } from "@usedapp/core";
import { BigNumber, constants } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useRef, useState } from "react";
import { BsHourglassSplit, BsPersonBoundingBox } from "react-icons/bs";
import Modal from "react-modal";
import BuyNowButton from "../images/BuyNowButton.png";
import { checkIfKYCCompleted } from "../util/api";
import useUnclaimedTokens from "../util/useUnclaimedTokens";
import { useIntersectionObserverRef } from "rooks";
const Veriff = require('@veriff/js-sdk');

interface Props {
    email: string;
    setEmail: (email: string) => void;

    onKYCCompleted: (newSignature: { r: string, s: string, v: number, emailHash: string; } | null) => void;
    onError: (error: string) => void;
}

Modal.setAppElement("#root");

const KYCSection: React.FC<Props> = ({ email, setEmail, onKYCCompleted, onError }) => {
    const { account } = useEthers();
    const [modalOpen, setModalOpen] = useState(false);

    const unclaimedTokens = useUnclaimedTokens() || constants.Zero;

    const [ref] = useIntersectionObserverRef((entries) => {
        if (!entries || !entries[0]) return;
        setIsOnScreen(entries[0].isIntersecting);
    });
    const [isOnScreen, setIsOnScreen] = useState(false);
    const veriff = useRef<any>(null);

    useEffect(() => {
        if (!veriff.current) {
            veriff.current = Veriff.Veriff({
                host: 'https://stationapi.veriff.com',
                apiKey: 'd4588c43-a138-4a78-af63-7c8098491d01',
                parentId: 'veriff-root',
                onSession: function (err: any, response: any) {
                    // window.location.replace(response.verification.url);
                    if (err) {
                        console.error(err);
                        return;
                    }
                    const url = response.verification.url;
                    console.log(url)
                    // open in current tab
                    window.location.replace(url);
                }
            });
        }
        veriff.current.setParams({
            person: {
                givenName: ' ',
                lastName: ' '
            },
            vendorData: `${email}`
        });
    }, [email]);

    useEffect(() => {
        if (!veriff.current || !isOnScreen || !modalOpen) return;
        veriff.current.mount();
    }, [isOnScreen, modalOpen]);

    const completeKYC = async () => {
        if (!account) {
            alert("Please connect your wallet");
            return;
        }

        if (!email) {
            onError("Please enter your email");
            return;
        }

        localStorage.setItem("email", email);
        try {
            const response = await checkIfKYCCompleted(email, account);
            console.log(response);
            if (response.ok) {
                if ("r" in response)
                    onKYCCompleted(response);
                else
                    onKYCCompleted(null);
            } else {
                // await logKYCAttempt(email);
                // window.open(`https://verify.passbase.com/xvr-projectsystem-ou-sandbox?p=${Buffer.from(JSON.stringify({ email })).toString("base64")}`, "_blank");
                setModalOpen(true);
            }
        } catch (e) {
            onError("failed to verify kyc completion");
        }
    };

    return (
        <div className="flex flex-col items-center mt-6 border-t-white border-t-[1px]">
            <p className="text-white font-bold mt-12 text-4xl">KYC</p>
            <p className="text-white font-bold text-2xl my-6">
                To access your {unclaimedTokens && prettyFormatEther(unclaimedTokens)} XVR tokens you will need to complete a KYC procedure.
            </p>
            <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="py-2 px-4 max-w-[20rem] mx-auto bg-[#170017] border-2 border-white text-white rounded-md"
                placeholder="Enter your e-mail"
                type="email"
            />

            <div className="relative mx-auto w-full md:w-1/2 lg:w-[45%] lg:h-[7rem] flex items-center justify-center align-middle cursor-pointer" onClick={completeKYC}>
                <img className="w-full h-full object-contain m-8" alt="" src={BuyNowButton} />
                <div className="absolute left-0 right-0 top-0 bottom-0 mx-16 my-6 flex items-center align-middle">
                    <p className="w-full text-white font-bold text-2xl text-center uppercase select-none">
                        complete kyc
                    </p>
                </div>
            </div>

            <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        zIndex: 1000,
                    },
                    content: {
                        border: "none",
                        borderRadius: "0.5rem",
                        padding: "0",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "transparent",
                    },
                }}
                contentLabel="KYC Modal"
            >
                <div className="flex flex-col items-center px-12 py-6 md:py-12 w-[95vw] md:w-[50vw] text-white font-bold bg-[#170017] gap-4 rounded-xl">
                    <p className="font-bold text-2xl my-6">
                        To access your {unclaimedTokens && prettyFormatEther(unclaimedTokens)} XVR tokens you will need to complete a KYC procedure.
                    </p>
                    <div className="flex flex-row items-center gap-4">
                        <div className="w-[30px] h-[30px]">
                            <BsPersonBoundingBox size={30} />
                        </div>
                        <p>
                            If you have not completed the KYC procedure, please click the button below.
                        </p>
                    </div>

                    {/* <button
                        className="py-2 px-4 bg-[#170017] border-2 border-white text-white rounded-md"
                        onClick={() => {
                            setModalOpen(false);
                            logKYCAttempt(email);
                            window.open(`https://verify.passbase.com/xvr-projectsystem-ou-sandbox?p=${Buffer.from(JSON.stringify({ email })).toString("base64")}`, "_blank");
                        }}
                    >
                        Complete KYC
                    </button> */}

                    <div
                        ref={ref}
                        id="veriff-root"
                        className="w-full !text-white"
                    />

                    <div className="flex flex-row items-center gap-4">
                        <div className="w-[30px] h-[30px]">
                            <BsHourglassSplit size={30} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>
                                If you have already completed the KYC procedure, please wait for the verification to complete.
                                You will receive an email when the verification is complete.
                            </p>
                            <p className="font-bold text-red-200">
                                Make sure to also check your SPAM folder.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default KYCSection;

export const prettyFormatEther = (value?: BigNumber) => {
    if (!value) return "";
    if (value.eq(0)) return "";

    const str = formatEther(value);
    const [whole, decimal] = str.split(".");
    if (decimal)
        return `${whole}.${decimal?.slice(0, 2)}`;
    return whole;
};